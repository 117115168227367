:root {
  --rsbs-backdrop-bg: transparency;
  --rsbs-bg: transparency;
  --rsbs-handle-bg: transparency;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-header] {
  display: none;
}

[data-rsbs-footer] {
  display: none;
}

.title {
  display: flex;
  align-items: center;
  font-family: HelloFont;
  position: relative;
  width: fit-content;
}

.img {
  position: absolute;
  right: -14px;
  top: -10px;
}
