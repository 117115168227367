.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  /* height: var(--default-tab-height); */
  /* max-height: -webkit-fill-available; */
  background-color: #7fd7f8;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.join {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fcfe01;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.item {
  background: #ffffff;
  margin: 0 auto;
  line-height: 17px;
  box-sizing: border-box;
}

.item > div {
  display: flex;
}

.item span {
  color: #ed4593;
}

.rules-btn {
  text-align: center;
}

.dot {
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #bff501;
  text-align: center;
  font-weight: 500;
}

.rules {
  overflow: hidden;
  transition: height 0.3s ease;
}

.hide {
  height: 0;
}

.show {
  height: auto;
}

.flex {
  width: 100%;
  text-align: center;
}
