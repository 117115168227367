.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-tab-height);
  max-height: -webkit-fill-available;
  flex: 1;
  margin-bottom: 100;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
}
