.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: var(--default-tab-height-without-header);
  max-height: -webkit-fill-available;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.content_usable {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  height: 500px;
}
