.flex_row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav_tab_container {
  width: 100%;
  height: 100%;
}

.nav_tab {
  composes: flex_row_center;
  justify-content: flex-start;
  background-color: #ffffff;
  padding-left: 10px;
}

.nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-right: 33px; */
  position: relative;
  height: 100%;
  padding: 10px;
}

.nav_active_tab {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 3px;
  z-index: 1;
}

.nav_static_tab {
  color: #00000066;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  margin-bottom: 3px;
}

.tab_content {
  padding: 3px;
}

.simple_header_container {
  composes: flex_row_center;
  width: 100%;
  height: 60px;
  background-color: #a9a9a91a;
}

.simple_header_icon {
  composes: flex_row_center;
  height: 100%;
  width: 10%;
  font-size: 100px;
  justify-content: center;
}

.simple_header_title {
  composes: flex_row_center;
  height: 100%;
  width: 300px;
  font-size: 19px;
  font-weight: bold;
  justify-content: center;
}

.toast_snackbar {
  /* visibility: hidden; */
  min-width: 250px;
  margin-left: -125px;
  color: #111;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

.toast_content {
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
}

.toast_icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.toast_snackbar.toast_show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

ul li {
  list-style: none;
}
.package_status {
  padding: 10px 0 0 0;
}
.package_status .status_list {
  margin: 0;
  padding: 0;
  margin-top: -5px;
  padding-left: 8px;
  list-style: none;
}
.package_status .status_list > li {
  border-left: 2px solid #9a9a9a;
  text-align: left;
}
.package_status .status_list > li:before {
  /* 流程点的样式 */
  content: "";
  border: 3px solid #9c9c9c;
  background-color: #9c9c9c;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  margin-left: -7px;
  margin-right: 10px;
}
.package_status .status_box {
  overflow: hidden;
}
.package_status .status_list > li {
  height: auto;
  width: 95%;
}
.package_status .status_list {
  margin-top: -8px;
}
.package_status .status-box {
  position: relative;
}
.package_status .status-box:before {
  content: " ";
  background-color: #f3f3f3;
  display: block;
  position: absolute;
  top: -8px;
  left: 20px;
  width: 10px;
  height: 4px;
}
.package_status .status_list {
  margin-top: 0px;
}
.status_list > li:not(:first-child) {
  padding-top: 10px;
}
.status_content_before {
  text-align: left;
  margin-left: 25px;
  margin-top: -20px;
}
.status_content_latest {
  text-align: left;
  margin-left: 25px;
  color: #62ffd0;
  margin-top: -20px;
}
.status_time_before {
  text-align: left;
  margin-left: 25px;
  font-size: 10px;
  margin-top: 5px;
}
.status_time_latest {
  text-align: left;
  margin-left: 25px;
  color: #62ffd0;
  font-size: 10px;
  margin-top: 5px;
}
.status_line {
  border-bottom: 1px solid #ccc;
  margin-left: 25px;
  margin-top: 10px;
}
.list {
  padding: 0 20px;
  background-color: #f8f8f8;
  margin: 10px 0 0 25px;
  border: 1px solid #ebebeb;
}
.list li {
  line-height: 30px;
  color: #616161;
}
