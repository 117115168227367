body {
  margin: 0;
  height: 100vh;
  max-height: -webkit-fill-available;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  background-color: #f5f5f8;
  padding-bottom: 3px;
}

/* ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

@font-face {
  font-family: 'PingFang-SC-Semibold';
  src: local('PingFang-SC-Semibold'), url(https://ndj.img.cbatime.com.cn/niudanji_static/fonts/PingFang-SC-Semibold.ttf);
}

@font-face {
  font-family: 'PingFang-SC';
  src: local('PingFang-SC'), url(https://ndj.img.cbatime.com.cn/niudanji_static/fonts/PingFang-SC.ttf);
}

@font-face {
  font-family: 'Poppins-ExtraBoldItalic_zitidi';
  src: local('Poppins-ExtraBoldItalic_zitidi'),
    url(https://ndj.img.cbatime.com.cn/niudanji_static/fonts/Poppins-ExtraBoldItalic_zitidi.com.ttf);
}

@font-face {
  font-family: 'YouSheBiaoTiHei-2';
  src: local('YouSheBiaoTiHei-2'), url(https://ndj.img.cbatime.com.cn/niudanji_static/fonts/YouSheBiaoTiHei-2.ttf);
}

@font-face {
  font-family: 'Literary-Black';
  src: local('Literary-Black'), url(https://ndj.img.cbatime.com.cn/niudanji_static/fonts/Literary-Black.ttf);
}

:root {
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;

  --color-error: #ff0000;
  --color-separator: #dddddd;
  --color-text-dim: #888888;

  --default-tab-height: calc(100vh - 60px);
  --default-tab-height-without-header: calc(100vh - 0px);
}

input,
textarea {
  border: 0;
  -webkit-appearance: none;
}

img[src=''],
img:not([src]) {
  opacity: 0;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
}
