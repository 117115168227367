.prc-dotGroup {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: -50px;
  left: calc(50vw - 25px);
}
.carousel__dot {
  height: 2px;
  width: 2px;
  margin: 3px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #ffffff;
}
.carousel__dot--selected {
  background-color: #ffffff99;
}

.carousel__slide-focus-ring {
  display: none;
}
