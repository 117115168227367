.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: var(--default-tab-height);
  max-height: -webkit-fill-available;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 50px;
}
