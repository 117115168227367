.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: var(--default-tab-height-without-header);
  max-height: -webkit-fill-available;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-top: 10px; */
}

* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
.package-info {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background-color: #fff;
  width: 100%;
  padding-left: 14px;
  padding-top: 30px;
  margin-top: 10px;
}
ul.package-status {
  width: 100%;
}
ul.package-status li.package-status-list {
  color: #666;
  font-size: 12px;
  border-left: 1px dotted #979797;
  padding-left: 15px;
  position: relative;
  padding-bottom: 29px;
}
ul.package-status li.package-status-list:last-child {
  border: 0;
}
ul.package-status li .status-title {
  font-size: 14px;
  margin-bottom: 2px;
}
ul.package-status li .status-details {
  line-height: 15px;
  width: 330px;
}
ul.package-status li.package-status-list i {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: absolute;
  left: -1px;
  border-radius: 50%;
}
ul.package-status li.package-status-list i.icon-package-fh {
  background: url(https://ndj.img.cbatime.com.cn/niudanji_static/images/type_received_address.png) no-repeat center;
  background-size: 100%;
}
ul.package-status li.package-status-list i.icon-package-lj {
  background: url(https://ndj.img.cbatime.com.cn/niudanji_static/images/type_received.png) no-repeat center;
  background-size: 100%;
}
ul.package-status li.package-status-list i.icon-package-ys {
  background: url(http://www.jq22.com/img/cs/500x500-3.png) no-repeat center;
  background-size: 100%;
}
ul.package-status li.package-status-list i.icon-package-ps {
  background: url(http://www.jq22.com/img/cs/500x500-4.png) no-repeat center;
  background-size: 100%;
}
ul.package-status li.package-status-list i.icon-package-qs {
  background: url(http://www.jq22.com/img/cs/500x500-5.png) no-repeat center;
  background-size: 100%;
}
ul.package-status li.package-status-list span {
  width: 6px;
  height: 6px;
  display: inline-block;
  position: absolute;
  left: 2.2px;
  background: #aaaaaa;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-top: 4px;
}
