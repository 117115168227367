.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: var(--default-tab-height-without-header);
  max-height: -webkit-fill-available;
  /* position: relative; */
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f5f8;
  padding-bottom: 120px;
}

.content_detail {
  composes: content;
  background-color: #f5f5f8;
}

.list img {
  width: 100%;
}

.list p {
  width: 100%;
}

.btn {
  position: fixed;
  bottom: 106px;
  right: 20px;
}
