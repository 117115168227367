.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: var(--default-tab-height-without-header);
  max-height: -webkit-fill-available;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-top: 10px; */
  background-color: #f5f5f8;
}

.custom-input::placeholder {
  color: #000000;
  background-color: #000000;
}

.custom-input {
  white-space: pre;
}
